body {
  color: $font-color;

  h1, h2, h3, h4, h5, h6 {
    color: $font-color;
  }
  p, a, span, li, dt, dd, th, td, div {
    font-weight: 400;
    color: $font-color;
    letter-spacing: .12em;
  }
  h1, h2, h3, h4, h5, h6, p, a, span, li, dl, dt, dd, th, td, div, figure {
    margin: 0;
    line-break: strict;
  }
  a {
    transition: .5s;
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  img {
    max-width: 100%;
  }
}

.pc {
  @include sp {
    display: none !important;
  }
}
.sp {
  @include pc {
    display: none !important;
  }
}

@include pc {
  #allbox {
    overflow: hidden;
  }
}

.container {
  max-width: 1060px;
  margin-inline: auto;
  padding-inline: 50px;
  @include sp {
    padding-inline: 25px;
  }
}

.text_arrow {
  padding: 0 17px 9px 0;
  display: inline-block;
  @include Cormo(700);
  font-size: 15px;
  letter-spacing: .12em;
  color: $theme-color;
  line-height: 1;
  position: relative;
  &::before, &::after {
    content: '';
    display: block;
    position: absolute;
  }
  &::before {
    width: 100%;
    height: 1px;
    background-color: $theme-color;
    left: 0;
    bottom: 0;
  }
  &::after {
    width: 12px;
    height: 4px;
    background: url(/assets/img/common/arrow.svg) no-repeat 0 / contain;
    right: 0;
    top: 7px;
    transition: .3s;
  }
  &:hover {
    color: $theme-color;
    &::before {
      animation: linkLine .5s linear forwards;
    }
    &::after {
      transform: translateX(3px);
    }
  }
}

@keyframes linkLine {
  0% {
    transform: scaleX(1);
    transform-origin: right;
  }

  50% {
      transform: scaleX(0);
      transform-origin: right;
  }
  51% {
      transform: scaleX(0);
      transform-origin: left;
  }
  100% {
      transform: scaleX(1);
      transform-origin: left;
  }
}

.link_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  border: 1px solid $theme-color;
  color: $theme-color;
  width: min(100%, 230px);
  height: 47px;
  i {
    font-size: 17px;
  }
  svg {
    transition: $transition-base;
  }
  .line {
    width: 17px;
    fill: $theme-color;
  }
  .mail {
    width: 14px;
    fill: none;
    stroke: $theme-color;
    stroke-miterlimit: 10;
    stroke-width: 1.5px;
  }
  span {
    @include YuMin;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .1em;
    color: $theme-color;
    transition: $transition-base;
  }

  &:hover {
    color: #fff;
    background-color: $theme-color;
    .line {
      fill: #fff;
    }
    .mail {
      stroke: #fff;
    }
    span {
      color: #fff;
    }
  }
}

.content-head-banner {
  position: relative;
  margin-top: 80px;
  .container {
    max-width: 1400px;
  }
  strong, small, li, a {
    color: $theme-color;
  }
  h2 {
    text-align: center;
    strong, small {
      display: block;
      line-height: 1;
    }
    strong {
      @include Cormo(600);
      font-size: 55px;
      letter-spacing: .15em;
    }
    small {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: .2em;
      margin-top: 20px;
    }
  }
  .pan {
    display: flex;
    justify-content: flex-end;
    li, a {
      @include YuMin;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: .18em;
      line-height: 1;
    }
    li {
      + li {
        &::before {
          content: '';
          display: inline-block;
          position: relative;
          width: 20px;
          height: 1px;
          background-color: $theme-color;
          margin-inline: 10px;
          bottom: 4px;
        }
      }
    }
    a {
      &:hover {
        opacity: .7;
      }
    }
  }

  @include sp {
    margin-block: 30px;
    h2 {
      strong {
        font-size: 35px;
      }
      small {
        font-size: 12px;
        margin-top: 11px;
      }
    }
    .pan {
      display: none;
    }
  }
}

.img_carousel {
  overflow: hidden;
  ul {
    animation: imgCarousel 100s linear infinite;
    column-gap: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 10076px;
  }
  @include sp {
    ul {
      column-gap: 6px;
      width: 5974px;
    }
  }
}
@keyframes imgCarousel {
  100% {
    transform: translateX(-5043px);
    @include sp {
      transform: translateX(-2990px);
    }
  }
}

@keyframes scrollLine {
  0% {
    transform: scaleX(1);
  }
  100% {
    transform: scaleX(0);
  }
}