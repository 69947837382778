.is--home {
  .rwd002-container {
    margin-top: 0;
    background-color: #edece9;
  }
  .sec_ttl {
    line-height: 1;
    strong {
      @include Cormo(600);
      font-size: 45px;
      letter-spacing: 0.13em;
      color: $theme-color;
    }
    small {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.2em;
      color: $theme-color;
      margin-left: 15px;
    }
  }

  .mainvisual {
    height: calc(100vh - 100px);
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 155px;
      background-color: #edece9;
      left: 0;
      bottom: 0;
      z-index: -1;
    }
    .movie_wrap {
      opacity: 0;
      video {
        object-fit: cover;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;
      }
    }

    .catch {
      position: relative;
      img,
      span,
      &::before {
        opacity: 0;
      }
      img {
        width: 446px;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.32em;
        color: $theme-color;
        display: block;
        line-height: 1;
        margin-top: 20px;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 67px;
        height: 80px;
        background: url(/assets/img/home/mv_parts@2x.png) no-repeat 0 / contain;
        left: 2px;
        bottom: -100px;
      }
    }

    .news_wrap {
      a {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px 13px;
      }
      p {
        line-height: 1;
      }
      .ttl {
        font-size: 22px;
        @include Cormo(400);
        letter-spacing: 0.15em;
        color: $theme-color;
      }
      .date {
        @include Roboto(300);
        color: #666;
        font-size: 13px;
        letter-spacing: 0.12em;
      }
      .cate {
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.08em;
      }
      .arti_ttl {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.15em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 60px;
        box-sizing: border-box;
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 50px;
          height: 5px;
          background: url(/assets/img/home/mv_arrow.svg) no-repeat 0 / contain;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }

    @include pc {
      .movie_wrap {
        position: absolute;
        right: 0;
        top: 0;
        width: 66%;
        height: calc(100% - 60px);
      }
      .catch {
        position: absolute;
        left: 50px;
        top: 40%;
        padding-left: 80px;
      }
      .news_wrap {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 66%;
        height: 60px;
        a {
          height: 100%;
          max-width: 717px;
        }
        .arti_ttl {
          flex: 1;
        }
      }
      .scroll {
        width: 156px;
        font-size: 12px;
        @include Cormo(700);
        letter-spacing: 0.2em;
        color: $theme-color;
        line-height: 1;
        position: absolute;
        left: 26px;
        bottom: 210px;
        transform-origin: left top;
        transform: rotate(90deg);
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 110px;
          height: 1px;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &::before {
          background-color: #dadada;
        }
        &::after {
          background-color: $theme-color;
          animation: scrollLine 1.5s ease-in-out infinite alternate;
          transform-origin: left top;
        }
      }

      @include min(1400px) {
        .movie_wrap,
        .news_wrap {
          width: calc(100% - 475px);
        }
      }
    }

    &.loaded {
      .movie_wrap {
        opacity: 1;
        transition: 1s 0.2s ease-out;
      }
      .catch {
        img,
        span,
        &::before {
          opacity: 1;
        }
        img,
        span {
          transition: 1.2s 0.4s ease-out;
        }
        &::before {
          transition: 1.2s 0.6s ease-out;
        }
      }
    }
  }

  .block-about {
    padding-block: 123px 80px;
    .text_wrap {
      h3 {
        @include YuMin;
        font-size: 28px;
        font-weight: 500;
        line-height: 1.6;
        letter-spacing: 0.1em;
        color: $theme-color;
        margin-top: 25px;
      }
      .text {
        font-size: 15px;
        font-weight: 500;
        line-height: 2.1;
        letter-spacing: 0.06em;
        margin-top: 40px;
      }
      .more_wrap {
        margin-top: 22px;
      }
      .btn_wrap {
        margin-top: 31px;
        a {
          svg {
            width: 13px;
            fill: none;
            stroke: #7c5353;
            stroke-miterlimit: 10;
            transition: $transition-base;
            position: relative;
            bottom: 2px;
          }
          &:hover svg {
            stroke: #fff;
          }
        }
      }
    }
    @include pc {
      .container {
        max-width: 1360px;
        display: grid;
        grid-template-columns: 54% 1fr;
        column-gap: 90px;
      }
    }
  }

  .block-service {
    padding-block: 150px 137px;

    .contents_list {
      margin-top: 120px;
    }

    .contents_wrap {
      position: relative;
      &:first-of-type picture {
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 153px;
          height: 245px;
          background: url(/assets/img/home/service_parts@2x.png) no-repeat 0 / contain;
          left: -94px;
          bottom: -81px;
        }
      }
      picture {
        display: block;
      }
      .text_wrap {
        .service_ttl {
          width: 250px;
          position: absolute;
          left: 328px;
          top: -10px;
        }
        .text {
          margin-top: 37px;
          font-size: 15px;
          font-weight: 500;
          line-height: 2.1;
          letter-spacing: 0.06em;
        }
        .more_wrap {
          margin-top: 33px;
        }
      }
    }

    .bn_wrap {
      max-width: 720px;
      margin: 50px auto 0;
      li {
        + li {
          margin-top: 25px;
        }
      }
      a {
        display: grid;
        place-content: center;
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 28px;
          aspect-ratio: 1 / 1;
          background: url(/assets/img/common/arrow_round.svg) no-repeat 0 / contain;
          right: 16px;
          bottom: 17px;
        }
        dl {
          text-align: center;
          dt {
            @include Cormo(400);
            line-height: 1;
            letter-spacing: 0.13em;
          }
          dd {
            font-weight: 700;
            line-height: 1;
          }
        }
        &.base {
          border: 1px solid $theme-color;
          box-sizing: border-box;
          height: 140px;
          dt,
          dd {
            color: $theme-color;
          }
          dt {
            font-size: 32px;
            position: relative;
            &::before {
              content: "";
              display: block;
              position: absolute;
              width: 19px;
              height: 20px;
              background: url(/assets/img/common/icon_cart.svg) no-repeat 0 / contain;
              left: -32px;
              top: 0;
              bottom: 0;
              margin: auto;
            }
          }
          dd {
            font-size: 14px;
            letter-spacing: 0.12em;
            margin-top: 10px;
          }
          .logo {
            width: 86px;
            position: absolute;
            right: 60px;
            bottom: 20px;
          }
        }
        &.school {
          height: 220px;
          background: url(/assets/img/home/school@2x.jpg) no-repeat center / cover;
          z-index: 1;
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: -1;
            opacity: 0;
            transition: $transition-base;
          }
          &::after {
            background-image: url(/assets/img/common/arrow_round_wh.svg);
          }
          dt,
          dd {
            color: #fff;
          }
          dt {
            font-size: 42px;
          }
          dd {
            font-size: 15px;
            letter-spacing: 0.2em;
            margin-top: 13px;
          }
        }
        &:hover {
          &.base {
            background-color: #f2eeee;
          }
          &.school {
            &::before {
              opacity: 1;
            }
          }
        }
      }
    }

    @include pc {
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 93.57%;
        height: 100%;
        background-color: #fff;
        left: 0;
        top: 0;
        z-index: -1;
        @include max(1100px) {
          width: calc(100% - 20px);
        }
      }
      .container {
        max-width: 1060px;
      }
      .contents_list {
        max-width: 910px;
      }
      .contents_wrap {
        display: flex;
        align-items: center;
        max-width: 800px;
        column-gap: 95px;
        picture {
          width: 354px;
        }
        &:nth-of-type(2) {
          margin: 15px 0 0 auto;
          flex-direction: row-reverse;
          justify-content: space-between;
          column-gap: 0;
        }
      }
    }
  }

  .block-shop {
    padding-block: 155px;
    picture {
      display: block;
    }
    .text_wrap {
      max-width: 336px;
      .text {
        font-size: 15px;
        font-weight: 500;
        line-height: 2.1;
        letter-spacing: 0.06em;
        margin-top: 30px;
      }
      .more_wrap {
        margin-top: 38px;
      }
    }
    @include pc {
      .container {
        max-width: calc(50% + 600px);
        padding-left: 0;
        margin-left: 0;
        display: grid;
        grid-template-columns: 52vw 1fr;
        column-gap: 75px;
        align-items: center;
      }
      picture {
        height: 307px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .block-news {
    padding-block: 87px 80px;
    background-color: #fff;

    .container {
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 138px;
        height: 174px;
        background: url(/assets/img/home/news_parts@2x.png) no-repeat 0 / contain;
        right: -60px;
        top: -200px;
      }
    }

    .news_list {
      margin-top: 46px;
      a {
        display: grid;
        grid-template-columns: 120px 1fr;
        column-gap: 25px;
        align-items: center;
        figure {
          aspect-ratio: 1 / 1;
          overflow: hidden;
          border: 1px solid #e4e4e4;
          box-sizing: border-box;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.3s;
          }
        }

        .text_wrap {
          .info {
            line-height: 1;
            .date {
              @include Roboto(300);
              font-size: 13px;
              letter-spacing: 0.12em;
              color: #666;
              line-height: 1;
            }
            .cate {
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 0.08em;
              line-height: 1;
              margin-left: 10px;
            }
          }
          .ttl {
            transition: $transition-base;
            font-size: 15px;
            font-weight: 500;
            line-height: 1.7;
            letter-spacing: 0.12em;
            margin-top: 10px;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        &.is-new .text_wrap .ttl::after {
          content: "NEW";
          @include Cormo(400);
          font-size: 14px;
          color: #76aeba;
          margin-left: 5px;
        }

        &:hover {
          img {
            transform: scale(1.1);
          }
          .ttl {
            opacity: 0.7;
          }
        }
      }

      @include pc {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px 45px;
      }
    }

    .link_wrap {
      margin-top: 17px;
      text-align: right;
      a {
        padding-right: 40px;
        &::after {
          width: 13px;
          height: 8px;
          background: url(/assets/img/home/icon_list.svg) no-repeat 0 / contain;
          top: 3px;
          right: 3px;
        }
        &:hover::after {
          transform: none;
        }
      }
    }
  }

  @include sp {
    #allbox {
      padding-top: 55px;
    }
    header {
      height: 80px;
      background: transparent;
      position: absolute;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 55px;
        background-color: #fff;
        left: 0;
        top: 0;
        z-index: -1;
      }
      h1 {
        right: auto;
        top: 0;
        a {
          display: grid;
          place-content: center;
          width: 98px;
          height: 80px;
          background-color: #fff;
          border-bottom-right-radius: 20px;
          padding: 0 7px 4px 0;
          box-sizing: border-box;
          &::before {
            content: "";
            display: block;
            width: 61px;
            height: 62px;
            background: url(/assets/img/common/logo.svg) no-repeat 0 / contain;
          }
        }
        picture {
          display: none;
        }
      }
    }

    .rwd002-container {
      .container {
        padding-inline: 30px;
      }
    }
    .sec_ttl {
      strong {
        font-size: 32px;
      }
      small {
        margin-left: 15px;
      }
    }

    .mainvisual {
      height: auto;
      background-color: #edece9;
      .movie_wrap {
        height: 350px;
        position: relative;
        z-index: 1;
      }

      .catch {
        height: 135px;
        padding-top: 30px;
        box-sizing: border-box;
        background-color: #fff;
        text-align: center;
        img {
          width: 278px;
        }
        span {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0.32em;
          display: block;
          line-height: 1;
          margin-top: 14px;
        }
        &::before {
          width: 34px;
          height: 41px;
          left: -313px;
          bottom: 27px;
          right: 0;
          margin: 0 auto;
        }
      }

      .news_wrap {
        margin: 35px 18px 0;
        a {
          display: flex;
          align-items: flex-end;
          flex-wrap: wrap;
          background-color: #fff;
          padding: 20px 20px 25px 25px;
          gap: 12px 13px;
        }
        .ttl {
          font-size: 22px;
          letter-spacing: 0.15em;
          position: relative;
          top: 2px;
        }
        .date {
          font-size: 13px;
          letter-spacing: 0.12em;
        }
        .cate {
          font-size: 13px;
          letter-spacing: 0.08em;
        }
        .arti_ttl {
          font-size: 14px;
          letter-spacing: 0.15em;
          padding-right: 25px;
          &::before {
            width: 12px;
            height: 4px;
            background-image: url(/assets/img/common/arrow.svg);
          }
        }
      }
    }

    .block-about {
      padding-block: 52px 70px;
      .text_wrap {
        margin-top: 35px;
        h3 {
          font-size: 21px;
          line-height: 1.5;
          margin-top: 25px;
        }
        .text {
          line-height: 2;
          margin-top: 20px;
        }
        .more_wrap {
          margin-top: 20px;
          text-align: right;
        }
        .btn_wrap {
          margin-top: 50px;
          a {
            width: 100%;
          }
        }
      }
    }

    .block-service {
      padding-block: 45px 70px;
      background-color: #fff;
      .contents_list {
        margin-top: 60px;
      }

      .contents_wrap {
        position: relative;
        margin-top: 60px;
        + .contents_wrap {
          margin-top: 52px;
        }

        &:first-of-type picture::before {
          width: 69px;
          height: 110px;
          left: auto;
          right: -7px;
          bottom: -69px;
        }

        .text_wrap {
          margin-top: 38px;
          .service_ttl {
            width: 188px;
            left: -10px;
            top: -83px;
          }
          .text {
            margin-top: 18px;
            font-size: 15px;
            font-weight: 500;
            line-height: 2.1;
            letter-spacing: 0.06em;
          }
          .more_wrap {
            margin-top: 18px;
            text-align: right;
          }
        }
        picture {
          img {
            width: 100%;
          }
        }
      }

      .bn_wrap {
        li + li {
          margin-top: 20px;
        }
        a {
          &::after {
            width: 21px;
            right: 12px;
            bottom: 12px;
          }
          &.base {
            height: 97px;
            &::after {
              display: none;
            }
            dt {
              font-size: 22px;
              &::before {
                left: -28px;
              }
            }
            dd {
              font-size: 12px;
              letter-spacing: 0.09em;
              margin-top: 10px;
            }
            .logo {
              width: 45px;
              right: 13px;
              bottom: 12px;
            }
          }
          &.school {
            height: 150px;
            dt {
              font-size: 32px;
            }
            dd {
              font-size: 12px;
              letter-spacing: 0.09em;
              margin-top: 10px;
            }
          }
        }
      }
      @media (min-width: 720px) {
        background-color: $gray-100;
        .contents_list {
          margin-top: 120px;
        }
        .contents_wrap {
          display: flex;
          align-items: center;
          max-width: 800px;
          column-gap: 95px;
          picture {
            width: 354px;
          }
          &:nth-of-type(2) {
            margin: 15px 0 0 auto;
            flex-direction: row-reverse;
            justify-content: space-between;
            column-gap: 0;
          }
          &:first-of-type picture {
            &::before {
              content: "";
              width: 153px;
              height: 245px;
              left: -94px;
              bottom: -81px;
            }
          }
          .text_wrap {
            margin-top: 0;
            max-width: 350px;
            .service_ttl {
              width: 250px;
              left: 328px;
              top: -10px;
            }
            .text {
              margin-top: 37px;
            }
            .more_wrap {
              margin-top: 33px;
              text-align: left;
            }
          }
        }
      }
    }

    .block-shop {
      padding-block: 60px 43px;
      picture {
        margin-left: -30px;
      }
      .text_wrap {
        margin-top: 31px;
        max-width: 100%;
        .text {
          line-height: 2;
          letter-spacing: 0.04em;
          margin-top: 23px;
        }
        .more_wrap {
          margin-top: 24px;
          text-align: right;
        }
      }
    }

    .block-news {
      padding-block: 70px 90px;

      .container {
        padding-inline: 25px;
        &::before {
          width: 69px;
          height: 87px;
          right: auto;
          left: 38px;
          top: -120px;
        }
      }

      .news_list {
        margin-top: 32px;
        li + li {
          margin-top: 20px;
        }
        a {
          display: grid;
          grid-template-columns: 95px 1fr;
          column-gap: 15px;
          align-items: center;
          .text_wrap {
            .info {
              .date {
                font-size: 12px;
              }
              .cate {
                font-size: 12px;
              }
            }
            .ttl {
              font-size: 11px;
              line-height: 1.5;
              margin-top: 7px;
              span {
              }
            }
          }
          &.is-new .text_wrap .ttl::after {
            font-size: 13px;
          }
        }
      }

      .link_wrap {
        margin-top: 17px;
        text-align: right;
        a {
          padding-right: 28px;
        }
      }
    }
  }
}
