.is--service {
  .rwd002-container {
    margin-top: 0;
  }

  &.is--list {
    .service_list {
      li {
        padding: 90px 30px;
        box-sizing: border-box;
        &:nth-of-type(2n) {
          flex-direction: row-reverse;
          background-color: #EEEDEA;
        }
        @include pc {
          display: flex;
          justify-content: center;
          align-items: center;
          column-gap: 100px;
        }
        figure {
          width: 370px;
        }
        .text_wrap {
          max-width: 500px;
          flex: 1;
          h3 {
            strong, small {
              color: $theme-color;
              display: block;
              line-height: 1;
            }
            strong {
              @include Cormo(600);
              font-size: 44px;
              letter-spacing: .13em;
            }
            small {
              font-size: 13px;
              font-weight: 500;
              letter-spacing: .2em;
              margin-top: 20px;
            }
          }
          .text {
            font-size: 15px;
            font-weight: 500;
            line-height: 2.1;
            letter-spacing: .08em;
            margin-top: 20px;
          }
          .btn_wrap {
            margin-top: 35px;
          }
        }
      }
    }
  }

  &.is--detail {
    p, dd {
      font-size: 15px;
      font-weight: 500;
      line-height: 2.1;
      letter-spacing: .08em;
      font-family: $font-family-sans-serif;
    }
    .rwd002-detail__head {
      display: none;
    }

    .theme_ttl {
      font-size: 24px;
      font-weight: 600;
      @include YuMin;
      line-height: 1;
      letter-spacing: .25em;
      color: #835152;
      position: relative;
      padding-bottom: 15px;
      text-align: center;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 50px;
        height: 1px;
        background-color: #835152;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .standard {
      position: relative;
      z-index: 1;
      text-align: center;
      dl {
        max-width: 780px;
        margin: 40px auto 0;
        dt {
          font-size: 20px;
          font-weight: 600;
          @include YuMin;
          letter-spacing: .25em;
          line-height: 1.7;
          color: #835152;
          @include pc {
            text-align: center;
          }
        }
        dd {
          margin-top: 26px;
          font-weight: 500 !important;
          line-break: normal !important;
          margin-bottom: 0;
        }
      }
      table {
        margin-top: 36px;
      }
      &.bg{
        padding-bottom: 100px;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 100vw;
          height: calc(100% - 180px);
          background-color: $theme-beige;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          z-index: -1;
        }
      }
    }

    .theme_table {
      width: min(100%, 780px);
      margin-inline: auto;
      tr {
        th, td {
          border: 1px solid #D9CFCA;
          box-sizing: border-box;
          padding: 10px 60px;
          font-size: 15px;
          font-weight: 600;
          line-height: 1.5;
          letter-spacing: .08em;
          @include YuMin;
        }
        th {
          text-align: center;
          color: #fff;
          background-color: #8F8078;
        }
        td {
          vertical-align: middle;
          &:nth-of-type(2){
            white-space: nowrap;
          }
        }
      }
    }

    .side_set {
      dl {
        dt {
          font-size: 20px;
          font-weight: 600;
          @include YuMin;
          line-height: 1.3;
          letter-spacing: .25em;
          color: #835152;
        }
        dd {
          line-break: normal;
          margin-bottom: 0;
        }
      }
      @include pc {
        display: flex;
        align-items: center;
        column-gap: 50px;
        figure {
          width: 420px;
        }
        dl {
          flex: 1;
        }
      }
    }

    .editor-template {
      margin-top: 100px;
      &.ttl-template {
        margin-bottom: 45px;
        + .editor-template {
          margin-top: 0;
        }
      }
    }

    .side-template {
      @include pc {
        + .side-template {
          margin-top: 70px;
        }
        &:nth-of-type(2n) {
          .side_set {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }

  &.is--article-1 {
    main {
      overflow: hidden;
    }
    .block-lead {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100vw;
        aspect-ratio: 3 / 2;
        background: url(/assets/img/service/bodycare/lead_bg@2x.jpg) no-repeat 0 / cover;
        left: calc(50% - 50vw);
        bottom: -370px;
        opacity: .8;
        z-index: -1;
      }

      .link_list {
        display: grid;
        grid-template-columns: repeat(3, 248px);
        justify-content: center;
        li {
          line-height: 1.4;
        }
        a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 45px;
          column-gap: 10px;
          text-align: center;
          span {
            @include YuMin;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: .25em;
            color: $theme-color;
          }
          &:hover {
            opacity: .7;
          }
        }
      }
      .text {
        margin-top: 135px;
        line-height: 2.5;
      }
      @include pc {
        .link_list {
          li {
            border-right: 1px solid $theme-color;
            &:first-of-type {
              border-left: 1px solid $theme-color;
            }
          }
          a {
            &::after {
              content: '';
              display: inline-block;
              position: relative;
              width: 14px;
              height: 14px;
              background: url(/assets/img/service/bodycare/arrow.svg) no-repeat 0 / contain;
            }
          }
        }
        .text {
          text-align: center;
        }
        picture {
          position: absolute;
        }
        .img1 {
          width: 218px;
          right: calc(50% + 340px);
          bottom: -40px;
        }
        .img2 {
          width: 270px;
          left: calc(50% + 337px);
          top: 110px;
        }
      }
    }

    .standard {
      figure {
        aspect-ratio: 160 / 79;
      }
      @include pc {
        &:not(.bg){
          margin-bottom: 130px;
        }
        &.bg_last {
          padding-bottom: 278px;
        }
      }
    }

    .block-massada {
      @include pc {
        margin-top: -208px;
      }

      .lead_wrap {
        position: relative;
        background-color: #fff;
        z-index: 1;
        &::before {
          content: '';
          display: block;
          position: absolute;
          width: 32px;
          height: 19px;
          background-color: #fff;
          clip-path: polygon(50% 0, 100% 100%, 0 100%);
          left: 0;
          right: 0;
          top: -18px;
          margin: auto;
        }
        dl {
          dt {
            font-size: 20px;
            font-weight: 600;
            @include YuMin;
            line-height: 1;
            letter-spacing: .25em;
            color: #835152;
          }
          dd {
            margin-top: 22px;
          }
        }
      }

      ul {
        margin-top: 67px;
        li {
          picture {
            display: block;
            border: 1px solid #E1E1E1;
            border-radius: 5px;
            box-sizing: border-box;
            overflow: hidden;
          }
          .text_wrap {
            margin-top: 20px;
            .name {
              color: #835152;
              font-size: 18px;
              font-weight: 500;
              line-height: 1.2;
              letter-spacing: .18em;
              @include YuMin;
              small {
                font-size: 14px;
                white-space: nowrap;
              }
            }
            .price {
              color: #835152;
              font-size: 15px;
              font-weight: 600;
              @include YuMin;
              line-height: 1.3;
              letter-spacing: .12em;
              margin-top: 8px;
              small {
                font-size: 12px;
              }
            }
            .text {
              font-size: 14px;
              font-weight: 500;
              line-height: 1.9;
              letter-spacing: .08em;
              margin-top: 13px;
            }
          }
        }
      }

      @include pc {
        .standard.bg_last {
          padding-bottom: 280px;
        }
        .lead_wrap {
          padding: 58px 60px 0 80px;
          box-sizing: border-box;
          display: grid;
          grid-template-columns: 222px 1fr;
          column-gap: 87px;
          align-items: center;
        }
        ul {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 56px 30px;
        }
      }
    }

  }

  &.is--article-2 {
    @include pc {
      .lead_text, .standard dd {
        text-align: center;
      }
    }

    .link_wrap {
      margin: 110px auto 0;
      max-width: 720px;
      h3, span, dt, dd {
        color: #835152;
      }

      h3 {
        font-size: 18px;
        font-weight: 500;
        @include YuMin;
        line-height: 1.5;
        letter-spacing: .25em;
        text-align: center;
      }

      ul {
        margin-top: 32px;
        @include pc {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 18px 16px;
          li:first-of-type {
            grid-column: 1 / 3;
          }
        }
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          border: 1px solid #835152;
          box-sizing: border-box;
          column-gap: 17px;
          svg {
            width: 27px;
            transition: $transition-base;
          }
          .line {
            width: 21px;
            fill: #835152;
          }
          .mail {
            width: 22px;
            fill: none;
            stroke: #835152;
            stroke-miterlimit: 10;
            stroke-width: 1.5px;
          }
          span {
            font-size: 22px;
            font-weight: 500;
            @include YuMin;
            letter-spacing: .11em;
          }
          &:hover {
            background-color: #F2EEEE;
          }
        }
        .base {
          height: 140px;
          &::after {
            content: '';
            display: block;
            position: absolute;
            width: 28px;
            aspect-ratio: 1 / 1;
            background: url(/assets/img/common/arrow_round.svg) no-repeat 0 / contain;
            right: 16px;
            bottom: 17px;
          }
          dl {
            text-align: center;
            dt {
              @include Cormo(400);
              line-height: 1;
              letter-spacing: .13em;
              font-size: 32px;
              position: relative;
              &::before {
                content: '';
                display: block;
                position: absolute;
                width: 19px;
                height: 20px;
                background: url(/assets/img/common/icon_cart.svg) no-repeat 0 / contain;
                left: -32px;
                top: 0;
                bottom: 0;
                margin: auto;
              }
            }
            dd {
              font-weight: 700;
              line-height: 1;
              font-size: 14px;
              letter-spacing: .12em;
              margin-top: 10px;
            }
          }
          .logo {
            width: 86px;
            position: absolute;
            right: 60px;
            bottom: 20px;
          }
        }
        .contact, .line {
          height: 82px;
        }
      }
    }
  }

  @include sp {
    .rwd002-container {
      .container {
        padding-inline: 30px;
      }
    }

    &.is--list {
      .service_list {
        li {
          padding: 75px 25px 70px;
          &:first-of-type {
            padding-top: 30px;
          }
          &:last-of-type {
            padding-bottom: 97px;
          }
          figure {
            width: 100%;
            aspect-ratio: 16 / 11;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .text_wrap {
            margin-top: 34px;
            max-width: 100%;
            h3 {
              strong {
                font-size: 32px;
              }
              small {
                margin-top: 10px;
              }
            }
            .text {
              line-height: 2;
              letter-spacing: .06em;
              margin-top: 18px;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              display: -webkit-box;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .btn_wrap {
              margin-top: 23px;
              text-align: right;
            }
          }
        }
      }
    }
  
    &.is--detail {
      .rwd002-detail {
        padding-inline: 25px;
        margin-top: 0;
      }
      p, dd {
        font-size: 14px;
        line-height: 2;
      }
      .theme_ttl {
        font-size: 22px;
        line-height: 1.2;
        padding-bottom: 15px;
      }
  
      .standard {
        dl {
          margin: 40px auto 0;
          dt {
            font-size: 18px;
          }
          dd {
            margin-top: 24px;
          }
        }
        &.bg {
          padding-bottom: 70px;
          &::before {
            height: calc(100% - 80px);
          }
        }
      }
  
      .theme_table {
        margin-inline: -10px;
        width: calc(100% + 20px);
        tr {
          th, td {
            padding: 13px 20px;
            font-size: 14px;
            line-height: 1.4;
          }
          th, td {
            &:first-of-type {
              width: calc(100% - 150px);
            }
            &:nth-of-type(2){
              width: 150px;
              text-align: center;
            }
          }
        }
      }
  
      .side_set {
        dl {
          dt {
            font-size: 20px;
            line-height: 1.3;
            letter-spacing: .25em;
          }
        }
      }
  
      .editor-template {
        margin-top: 78px;
        &.ttl-template {
          margin-bottom: 38px;
          + .editor-template {
            margin-top: 0;
          }
        }
      }
  
      .side-template {
        + .side-template {
          margin-top: 70px;
        }
      }
    }
  
    &.is--article-1 {
      .block-lead {
        display: flex;
        flex-direction: column-reverse;
        position: relative;
        &::before {
          aspect-ratio: 5 / 9;
          background: url(/assets/img/service/bodycare/lead_bg_sp@2x.jpg) no-repeat 0 / cover;
          left: -25px;
          bottom: -240px;
          @media(min-width:720px){
            background: url(/assets/img/service/bodycare/lead_bg@2x.jpg) no-repeat 0 / cover;
            width: 150vw;
            aspect-ratio: 3/2;
          }
        }
        .link_list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 268px;
          margin: auto;
          li {
            line-height: 1.4;
          }
          a {
            width: 100%;
            height: 45px;
            column-gap: 5px;
            &::before {
              content: '';
              display: inline-block;
              position: relative;
              width: 14px;
              height: 14px;
              background: url(/assets/img/service/bodycare/arrow.svg) no-repeat 0 / contain;
            }
            span {
              white-space: nowrap;
            }
          }
        }
        picture {
          display: block;
          margin-top: 40px;
          img{
            width: 100%;
          }
        }
        .text {
          margin-top: 40px;
          line-height: 2.2;
        }
      }
      .standard {
        &.bg_last {
          padding-bottom: 40px;
        }
        dl dt {
          text-align: center;
        }
      }
      .block-massada {
        .lead_wrap {
          padding-top: 40px;
          &::before {
            width: 32px;
            height: 19px;
            top: -18px;
          }
          h4 {
            width: 173px;
            margin: auto;
          }
          dl {
            margin-top: 35px;
            dt {
              font-size: 18px;
              text-align: center;
            }
            dd {
              margin-top: 20px;
            }
          }
        }
  
        ul {
          margin-top: 40px;
          li {
            + li {
              margin-top: 40px;
            }
            .text_wrap {
              margin-top: 20px;
              .price {
                margin-top: 8px;
              }
              .text {
                margin-top: 13px;
              }
            }
            img{
              width: 100%;
            }
          }
        }
      }
    }

    &.is--article-2 {  
      .link_wrap {
        margin-top: 52px;

        h3 {
          font-size: 15px;
        }

        ul {
          margin-top: 25px;
          li + li {
            margin-top: 13px;
          }
          a {
            column-gap: 10px;
            svg {
              width: 27px;
            }
            .mail {
              width: 19px;
            }
            span {
              font-size: 14px;
              letter-spacing: .2em;
            }
          }
          .base {
            height: 88px;
            &::after {
              display: none;
            }
            dl {
              dt {
                font-size: 22px;
                &::before {
                  left: -28px;
                }
              }
              dd {
                font-size: 12px;
                letter-spacing: .09em;
                margin-top: 5px;
              }
            }
            .logo {
              width: 45px;
              right: 13px;
              bottom: 2px;
            }
          }
          .contact, .line {
            height: 58px;
          }
        }
      }
    }
  }
}