footer {
  position: relative;
  .reserv_wrap {
    background-color: $theme-color;
    padding-block: 66px 68px;
    dt, dd, span {
      color: #fff;
    }
    .ttl {
      text-align: center;
      dt {
        font-size: 42px;
        @include Cormo(600);
        letter-spacing: .13em;
        line-height: 1;
      }
      dd {
        font-size: 14px;
        font-weight: 500;
        line-height: 1;
        letter-spacing: .2em;
        margin-top: 20px;
      }
    }

    .sns_list {
      margin-top: 42px;
      @include pc {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 14px;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
        width: 100%;
        height: 66px;
        border: 1px solid #fff;
        box-sizing: border-box;
        svg {
          transition: $transition-base;
        }
        .line {
          width: 21px;
          fill: #fff;
        }
        .mail {
          width: 22px;
          fill: none;
          stroke: #fff;
          stroke-miterlimit: 10;
          stroke-width: 1.5px;
        }
        i {
          font-size: 20px;
          color: #fff;
          transition: $transition-base;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          letter-spacing: .2em;
          transition: $transition-base;
        }

        &:hover {
          background-color: #fff;
          .line {
            fill: $theme-color;
          }
          .mail {
            stroke: $theme-color;
          }
          i, span {
            color: $theme-color;
          }
        }
      }
    }
  }

  .wh_wrap {
    padding-block: 120px 33px;
    .container {
      max-width: 860px;
    }
    a:hover {
      opacity: .7;
    }

    .right_wrap {
      p, a, span {
        color: $theme-color;
      }
      .top_wrap {
        .info_wrap {
          p {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: .16em;
            line-height: 2.1;
          }
        }
        .sns_list {
          @include pc {
            li + li {
              margin-top: 10px;
            }
          }
          a {
            display: flex;
            align-items: center;
            &:hover {
              opacity: .7;
            }
            i {
              &.fa-instagram {
                font-size: 19px;
              }
              &.fa-line {
                font-size: 19px;
              }
              &.fa-youtube {
                font-size: 16px;
              }
            }
            span {
              font-size: 15px;
              @include Cormo(600);
              line-height: 1;
              letter-spacing: .12em;
              margin-left: 10px;
            }
            small {
              font-size: 11px;
              font-weight: 500;
              letter-spacing: .08em;
              line-height: 1;
            }
          }
        }
      }

      nav {
        margin-top: 32px;
        padding-top: 24px;
        border-top: 1px solid #E4E4E4;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            line-height: 1;
            &:not(:last-of-type)::after {
              content: '/';
              @include Cormo(400);
              margin-inline: 10px;
              font-size: 12px;
              position: relative;
              color: $theme-color;
            }
            a {
              font-size: 12px;
            }
          }
        }
        .main_nav {
          li {
            &::after {
              bottom: 1px;
            }
          }
          a {
            font-weight: 500;
            letter-spacing: .2em
          }
        }
        .sub_nav {
          margin-top: 7px;
          li {
            &:active {
              top: 1px;
            }
          }
          a {
            @include Cormo(400);
            letter-spacing: .12em;
          }
        }
      }
    }
    @include pc {
      .main_wrap {
        display: grid;
        grid-template-columns: 128px 1fr;
        column-gap: 55px;
      }
      .right_wrap .top_wrap {
        display: flex;
        justify-content: space-between;
      }
    }

    .bottom_wrap {
      margin-top: 68px;
      @include pc {
        display: flex;
        justify-content: center;
      }
      p {
        @include Cormo(400);
        font-size: 12px;
        line-height: 1;
        letter-spacing: .13em;
      }
      @include pc {
        .copy::after {
          content: '/';
          margin-inline: 10px;
        }
      }
    }
  }

  @include sp {
    .reserv_wrap {
      padding-block: 54px;
      .container {
        padding-inline: 30px;
      }
      .ttl {
        text-align: center;
        dt {
          font-size: 33px;
        }
        dd {
          margin-top: 20px;
        }
      }
  
      .sns_list {
        margin-top: 42px;
        li + li {
          margin-top: 10px;
        }
        a {
          column-gap: 10px;
          height: 66px;
          .mail {
            width: 19px;
          }
          i {
            font-size: 20px;
          }
        }
      }
    }
  
    .wh_wrap {
      padding-block: 45px 26px;
      .logo {
        width: 115px;
        margin: auto;
      }
      .right_wrap {
        margin-top: 26px;
        .top_wrap {
          .info_wrap {
            p {
              line-height: 2;
              text-align: center;
            }
          }
          .sns_list {
            margin: 23px auto 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            max-inline-size: max-content;
            row-gap: 15px;
            li:first-of-type {
              grid-column: 1 / 3;
            }
            a {
              display: flex;
              align-items: center;
              i {
  
              }
            }
          }
        }
  
        nav {
          margin-top: 24px;
          ul {
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            li {
              line-height: 1;
              &:not(:last-of-type)::after {
                margin-inline: 10px;
              }
              &:nth-of-type(4)::after {
                display: none;
              }
            }
          }
          .main_nav {
            li {
            }
            a {
              letter-spacing: .2em
            }
          }
          .sub_nav {
            margin-top: 10px;
            li {
  
            }
            a {
              letter-spacing: .12em;
            }
          }
        }
      }
  
      .bottom_wrap {
        margin-top: 46px;
        text-align: center;
        .login {
          margin-top: 10px;
        }
      }
    }
  }
}

.pagetop {
  position: absolute;
  a {
    display: block;
    @include Cormo(400);
    letter-spacing: .12em;
    color: $theme-color;
    line-height: 1;
    &::before, &::after {
      content: '';
      display: block;
      position: absolute;
      margin: auto;
    }
  }
  @include pc {
    right: 27px;
    top: -30px;
    transform-origin: right bottom;
    transform: rotate(90deg);
    width: 128px;
    a {
      width: 100%;
      text-align: right;
      font-size: 11px;
      &::before, &::after {
        width: 63px;
        height: 1px;
        left: 0;
        top: 0;
        bottom: 0;
      }
      &::before {
        background-color: #DADADA;
      }
      &::after {
        background-color: $theme-color;
        animation: scrollLine 1.5s ease-in-out infinite alternate;
        transform-origin: right bottom;
      }
    }

  }
  @include sp {
    left: 0;
    right: 0;
    top: -50px;
    a {
      padding-top: 12px;
      max-inline-size: max-content;
      margin: auto;
      position: relative;
      &::after {
        width: 14px;
        height: 14px;
        border-left: 1px solid $theme-color;
        border-top: 1px solid $theme-color;
        left: 0;
        right: 0;
        top: 0;
        transform: rotate(45deg);
        margin: auto;
      }
    }
  }
}