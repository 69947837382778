.rwd002-profile {

  a {
    color: $primary;
    text-decoration: underline;
  }
  &__title {
    max-width: 100%;
    width: 1000px;
    margin: 0 auto;
    padding: 0 1.25rem;
    @include media-down(sm) {
      padding: 0 1.6875rem;
    }
  }
  &__entry {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 1.25rem;
    @include media-down(sm) {
      padding: 0 1.6875rem;
    }
    .is-main &{
      @include media-up(xl) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__table {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: none;
    @include media-down(sm) {
      max-width: 100%;
    }
    th, td {
      padding: 1em;
      background: transparent;
      border: solid 1px #E9E9E9;
      border-width: 0 0 1px;
      font-size: 1em;
      line-height: 2;
      @include media-down(sm) {
        display: block;
        padding-left: 0;
        padding-right: 0;
      }
      address {
        margin-bottom: 0;
      }
      iframe {
        max-width: 100%;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        & + p {
          margin-top: 1em;
        }
      }
    }

    th {
      vertical-align: top;
      border-color: #835152;
      color: #835152;
      font-weight: $font-weight-bold;
      letter-spacing: .1em;
      white-space: nowrap;
      @include media-up(md) {
        width: 150px;
      }
      @include media-down(sm) {
        padding-bottom: 0;
        border: none;
      }
    }
    td {
      letter-spacing: .16em;
      @include media-up(md) {
        padding-left: 2em;
      }
      @include media-down(sm) {
        padding-top: 0;
        padding-bottom: 0.9375rem;
      }
    }

    //写真のthはスマホ表示で削除
    .is-prof12 {
      th {
        @include media-down(sm) {
          display: none;
        }
      }
      td {
        @include media-down(sm) {
          padding-top: 1em;
        }
      }
    }
  }

  &__img {
    margin: 0;
    img {
      max-width: 100%;
      height: auto;
    }
    .is-caption {
      margin-top: 0.5em;
      font-size: 0.875rem;
    }
  }

  &__other {
    padding: 2em 1em 0;
    letter-spacing: .1em;
    @include media-down(md) {
      padding: 2em 0 0;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.is--profile {
  .rwd002-container {
    margin-top: 70px;
  }

  .img_carousel {
    margin-top: 30px;
  }

  .is-prof10, .is-prof11 {
    th, td {
      border: none;
      @include pc {
        padding-bottom: 40px;
      }
    }
  }
  tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    td {
      width: calc(100% - 150px);
    }
  }
  .is-prof11 {
    position: relative;
    height: 405px;
    box-sizing: border-box;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  
  @include sp {
    .rwd002-container {
      margin-top: 20px;
    }
  
    .img_carousel {
      margin-top: 30px;
    }
    
    @include max(769px){
      tr {
        display: block;
        th, td {
          display: block;
          width: 100%;
        }
      }
    }
  
    .is-prof10 td {
      padding-bottom: 35px;
    }
    .is-prof11 {
      height: 220px;
    }
  }
}