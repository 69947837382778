$gnav-height-md: 120px;
$gnav-height-xs: 55px;

.rwd002-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  padding-right: 0;

  @include media-up(lg) {
    position: relative;
    padding-right: 28px;
  }

  @include media-down(lg) {
    height: 65px;
    top: 0;
    background-color: $white;
    z-index: 100;
    overflow: hidden;
    align-items: flex-start;
  }

  &__logo {
    margin: auto;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    a {
      img {
        width: 100%;
      }
      &:hover {
        opacity: .7;
      }
    }
    @include pc {
      width: 235px;
      height: 170px;
      a {
        display: grid;
        place-content: center;
        grid-template-columns: 124px;
        width: 100%;
        height: 100%;
        background-color: #fff;
      }
      @include max(1000px){
        width: 140px;
        height: 140px;
        a {
          grid-template-columns: 100px;
        }
      }
    }
    @include sp {
      width: 195px;
      right: 6px;
      top: 10px;
    }
  }

}

.rwd002-header__drawerBtn {
  @extend .btn;
  position: relative;
  z-index: 101;
  height: 55px;
  width: 55px;
  background-color: fade-out($white, 0.5);
  margin-inline: auto 0;

  &:focus {
    box-shadow: none;
  }

  @include media-up(lg) {
    display: none;
  }

  &__bars {
    stroke: $theme-color;
  }

  &__bar01,
  &__bar02,
  &__bar03 {
    transform-origin: center;
    transition: $transition-base;
  }

  .is-active &__bar01 {
    transform: rotate(135deg) translateY(50%);
  }

  .is-active &__bar02 {
    transform: rotate(45deg);
    opacity: 0;
  }

  .is-active &__bar03 {
    transform: rotate(225deg) translateY(-50%);
  }
}
